import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './team.module.scss'
import { tr } from 'js/translations.js'

import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import IntroText from 'components/IntroText'
import Layout from 'components/Layout'
import Seo from 'components/Seo'
import TeamMember from 'components/TeamMember'

const Page = ({ data }) => {
  const locale = process.env.GATSBY_LOCALE
  const page = data['page'].nodes[0]
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]

  const introText = renderRichText(page.introText)
  return (
    <Layout>
      <div>
        <Seo page={page} locale={locale} />
        <Banner image={page.banner} locale={locale} />
        <Breadcrumb items={breadcrumb} />
        <div className='wrapper pad-sides'>
          <div className='inner'>
            <IntroText content={introText} />
            <div className={styles.team}>
              <div className={styles.inner}>
                {page.teamMembers.map((item, i) => (
                  <TeamMember
                    key={i}
                    item={item}
                    hasLink={true}
                    hasText={true}
                    locale={locale}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page: allContentfulTeamPage {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        teamMembers {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 0.83333333333
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          name
          jobTitle
          slug
        }
        seo {
          ...SeoFields
        }
      }
    }
  }
`
